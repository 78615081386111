<template>
  <div>
    <div>      
        <b-card title="">
          <div class="mb-1">
            <router-link
              :to="`/administrador/eventos_conf/${this.$route.params.event_id}`"
              class="link-none"
            >
              <feather-icon icon="ArrowLeftIcon" size="20" class="" />
            </router-link>
          </div>
        <p>Lives:</p>
        <b-form action="">
          <add-new-live
            :is-add-new-live-sidebar-active.sync="isAddNewLiveSidebarActive"
            @close="isAddNewLiveSidebarActive = false"
          />
          <edit-live
            v-if="isEditLiveSidebarActive"
            :is-edit-live-sidebar-active.sync="isEditLiveSidebarActive"
            :live="live"
            @refresh="refresh()"
            @close="isEditLiveSidebarActive = false"
          />
          <div class="d-flex justify-content-end mb-2">
            <b-form-input
              v-model="searchValue"
              class="d-inline-block mr-1"
              placeholder="Filtrar..."
              @keyup="filtrarLives"
            />
            <b-button
                  variant="primary"
                  @click="isAddNewLiveSidebarActive = true"
            >
              <span class="text-nowrap">Adicionar live</span>
            </b-button>
          </div>
        </b-form>
        <div class="grid ">
          <div class="grid-item img-live d-flex flex-column justify-content-end" style="max-width: 450px; height: 250px;" v-for="live in lives" :key="lives.id">
            <div
              @click="openEditLiveSidebar(live)"
            >
              <img
                style=" object-fit: cover;  height: 200px;"
                class="rounded w-100  border border-primary "
                :src="BaseUrl + '/storage/images/event_live_banner/' + live.banner"
                :alt="live.title"
              />
              <h3 class="font-weight-normal">{{ live.title }}</h3>
            </div>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { BCard } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import AddNewLive from "./AddNewLive.vue"
import EditLive from "./EditLive.vue"
export default {
  components: {
    AddNewLive,
    EditLive,
  },
  setup() {
    const isAddNewLiveSidebarActive = ref(false)
    const isEditLiveSidebarActive = ref(false)
    return {
      isAddNewLiveSidebarActive,
      isEditLiveSidebarActive
    }
  },
  data() {
    return {
      lives: [],
      live: {
        id: null,
      },
      BaseUrl: process.env.VUE_APP_API_BASE,
      searchValue: null,
    };
  },
  beforeMount() {
    this.fetchLives();
  },
  created() {
    
  },
  watch: {
    isAddNewLivesSidebarActive(value) {
      if (!value) {
        this.fetchLives();
      }
    },
    isEditLiveSidebarActive(value) {
      if (!value) {
        this.fetchLives();
      }
    },
  },
  methods: {
    filtrarLives() {
      if (this.searchValue && this.searchValue.trim() !== '') {
        const termoPesquisa = this.searchValue.trim().toLowerCase();
        this.lives = this.lives.filter(live => live.title.toLowerCase().includes(termoPesquisa));
      } else {
        this.fetchLives();
      }
    },
    openEditLiveSidebar(live) {
      this.live = live;
      this.isEditLiveSidebarActive = true;
    },
    fetchLives() {
      const apiUrl = process.env.VUE_APP_API + `/events/lives/get-lives/${this.$route.params.event_id}`;
      axios
        .get(apiUrl)
        .then((response) => {
          this.lives = response.data.lives;
        })
        .catch((error) => {
          console.error("Error fetching lives:", error);
        });
    },
  },
};
</script>
<style scoped>
.img-live {
  border-color: #161D31 !important;
  border-width: 2px !important;
  margin-bottom: 10px;
}

.link-none {
  text-decoration: none !important;
  color: inherit;
}
.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.bg-dark-blue {
  background-color: #161d31 !important;
}

@media (max-width: 768px) {
  .grid {
    grid-template-columns: 1fr;
  }
}
</style>